import React from 'react';
import {
  Box,
  Button,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

const MintBoardSubmitButton = ({connected, approved, approve, mint}) => {
  const SubmitButton = styled(Button)({
    '&.MuiButton-root': {
      width: '50%',
      display: 'inline-block',
      color: 'white',
      backgroundColor: 'rgba(255,0,0,.8)',
      style: 'center',
      borderRadius: '4',
      align: 'center',
    },
  })

  return (
    <Grid item xs={12}>
      <Box textAlign={'center'}>
        {approved && (
          <SubmitButton onClick={mint}>
            Mint
          </SubmitButton>
        )}

        {!approved && (
          <SubmitButton
            disabled={!connected}
            onClick={approve}
          >
            Approve
          </SubmitButton>
        )}
      </Box>
    </Grid>
  )
}

export default MintBoardSubmitButton
