import { CardMedia, Grid } from '@mui/material';

export default function CarouselCard({ imageLeft, imageRight }) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6} pt={2}>
                        <CardMedia 
                            component="img" 
                            src={imageLeft} 
                            sx={{
                                height: {xs: "225px", md: "305px"},
                                objectFit: "scale-down",
                                paddingRight: {xs: "0", lg: "0.5em"}
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} pt={2}>
                        <CardMedia 
                            component="img" 
                            src={imageRight} 
                            sx={{
                                height: {xs: "225px", md: "305px"},
                                objectFit: "scale-down",
                                paddingLeft: {xs: "0", lg: "0.5em"}
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
