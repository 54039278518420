type ApplicationAddresses = {
    FLAME_CULTIST: string,
    PLAGUE_DOCTOR: string,
    FIRE_LORD: string,
    BUSD_ERC20: string
}

const TESTNET_BSC_ADDRESSES = {
    FLAME_CULTIST: "0x47F79F386451CAd3f33A609Cb114B43924713986",
    PLAGUE_DOCTOR: "0xe27efa907c2ce4e181be8d5960febd22aaffb400",
    FIRE_LORD: "0xc208caFC4ddE4D9dc321c4c4cEFAde1Dfd8daFa2",
    BUSD_ERC20: "0x5605bD5f41690F962a844cCAF8Bc4dc882223EDE"
}

const BSC_ADDRESSES = {
    FLAME_CULTIST: "0xe98e64441A2C6D69fC3DE85370019bAd4256625d",
    PLAGUE_DOCTOR: "0xf074BA8c8D2eeFB23Ae63d433B62fe9E677241D1",
    FIRE_LORD: "0x3432c5e0Ac7C56a612ad6c69f9b3a1F8E62E7368",
    BUSD_ERC20: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
}

const ADDRESSES = {} as ApplicationAddresses;

const test = false;
if (test) {
    ADDRESSES.FLAME_CULTIST = TESTNET_BSC_ADDRESSES.FLAME_CULTIST;
    ADDRESSES.PLAGUE_DOCTOR = TESTNET_BSC_ADDRESSES.PLAGUE_DOCTOR;
    ADDRESSES.FIRE_LORD = TESTNET_BSC_ADDRESSES.FIRE_LORD;
    ADDRESSES.BUSD_ERC20 = TESTNET_BSC_ADDRESSES.BUSD_ERC20;
}
else {
    ADDRESSES.FLAME_CULTIST = BSC_ADDRESSES.FLAME_CULTIST;
    ADDRESSES.PLAGUE_DOCTOR = BSC_ADDRESSES.PLAGUE_DOCTOR;
    ADDRESSES.FIRE_LORD = BSC_ADDRESSES.FIRE_LORD;
    ADDRESSES.BUSD_ERC20 = BSC_ADDRESSES.BUSD_ERC20;
}

export default ADDRESSES;
