import { Grid, Typography } from "@mui/material"

const CurrentlyMinted = ({ tier, current, max }) => {
  return (
    <Grid container
      direction="column"
      pb={{ xs: 2, sm: 0 }}
      width={{xs: "100%", sm: "fit-content"}}
    >
      <Grid item>
        <Typography
          color='rgb(237,28,36)'
          variant="h5"
          align="center"
          noWrap={true}
          sx={{ fontWeight: 'bold' }}
        >
          Tier {tier}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          align="center"
          sx={{ fontWeight: 'bold' }}
        >
          {current}/{max}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CurrentlyMinted
