import { useMemo } from "react";
import { useEthers } from "@usedapp/core";
import { Contract, Signer, ethers } from "ethers";
import { Erc20 } from "./__generated__/Erc20";
import { ERC20Abi, OBurnNFTAbi } from "./abis";
import { JsonRpcProvider } from "@ethersproject/providers";
import ADDRESSES from "./addresses";

const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org/");
//const provider = new ethers.providers.JsonRpcProvider("https://data-seed-prebsc-2-s3.binance.org:8545");
// const provider = new ethers.providers.JsonRpcProvider("https://rpc-mainnet.maticvigil.com");

export function useERC20(address: string) {
  const { library } = useEthers();
  return useMemo(() => {
    return getERC20(address, (library as JsonRpcProvider)?.getSigner());
  }, [address, library]);
}

export function getERC20(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, ERC20Abi, signer) as Erc20;
  }
  else {
    return new Contract(address, ERC20Abi, provider) as Erc20;
  }
}

export function getFlameCultist(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, OBurnNFTAbi, signer);
  }
  else {
    return new Contract(address, OBurnNFTAbi, provider);
  }
}

export function useFlameCultist() {
  const { library } = useEthers();
  
  return new Contract(
    ADDRESSES.FLAME_CULTIST,
    OBurnNFTAbi,
    library
  );
}

export function usePlagueDoctor() {
  const { library } = useEthers();
  
  return new Contract(
    ADDRESSES.PLAGUE_DOCTOR,
    OBurnNFTAbi,
    library
  );
}

export function useFireLord() {
  const { library } = useEthers();
  
  return new Contract(
    ADDRESSES.FIRE_LORD,
    OBurnNFTAbi,
    library
  );
}
