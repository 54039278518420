import { useDispatch } from "react-redux";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import fire from './images/video3.mp4';
import './components/style.css';
import { createTheme } from '@mui/material/styles'
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import MainBoard from "./components/MainBoard";

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      custom: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      custom?: string
    }
  }
}

let theme = createTheme({




  typography: {
    'fontFamily': `Poppins`

  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ED1C24',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#4C5454',
      // light: will be calculated from palette.secondary.main,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Poppins"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: 'white',
          textColorPrimary: 'white',
          indicatorColor: 'red'

        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        }
      }
    },
    MuiAutocomplete: {},
    MuiOutlinedInput: {},
    MuiSelect: {

    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {

            '& fieldset': {
              borderColor: 'white',
              border: 'solid 3px',
              bgColor: 'white',
            },

            borderColor: "rgba(0, 255, 255, 1)",
            "&:hover fieldset": {
              borderColor: "rgba(0, 255, 255, 1)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(0, 255, 255, 1)",
            },
          },
        },
      },
      defaultProps: {
        inputProps: {
          style: {
            fontSize: "12px",
            // height: '.85rem',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },

  },
})

theme.typography.body2 = {
  fontSize: '1.1rem',
  '@media (max-width:1000px)': {
    fontSize: '.8rem',
  },


  '@media (max-width:600px)': {
    fontSize: '.6rem',
  },

};

theme.typography.h6 = {
  fontSize: '1.3rem',
  '@media (max-width:1000px)': {
    fontSize: '.9rem',
  },


  '@media (max-width:600px)': {
    fontSize: '.6rem',
  },

};

function App() {
  useDispatch()
  const [tab, setTab] = React.useState(3)

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    // <DAppProvider config={config}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>

          <div style={{
            backgroundColor: 'black',
            width: '100%',
            height: '100%',
            opacity: '1',
            zIndex: '-3',
            position: 'fixed'
          }} />

          <div style={{
            backgroundColor: 'black',
            width: '100%',
            height: '100%',
            opacity: '.5',
            zIndex: '-1',
            position: 'fixed'
          }} />

          <video autoPlay loop muted style={{


            zIndex: '-2',
            position: 'fixed',
            width: '100%',
            height: '100%',
            objectFit: 'cover',

          }}><source src={fire} type='video/mp4' />
          </video>


          <Tabs
            value={tab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#D97D54',

              },
            }}
            textColor='primary'
          >
            <Tab label={'Home'} id={'0'} href="https://www.onlyburns.com/"></Tab>
            <Tab label={'Swap'} id={'1'} href="https://www.kyotoswap.io/" ></Tab>
            <Tab label={'Vote'} id={'2'} href="https://app.onlyburns.com"></Tab>
            <Tab label={'Mint'} id={'3'}></Tab>
          </Tabs>


          {tab === 3 && <MainBoard></MainBoard>}


        </div>
      </ThemeProvider>
    // </DAppProvider>
  );
}
export default App;
