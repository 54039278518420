import {
  getERC20,
  useERC20,
  useFlameCultist,
  usePlagueDoctor,
  useFireLord
} from "./contracts";
import { Contract, utils } from "ethers";
import { useCall, useContractFunction } from "@usedapp/core";

import { PromiseOrValue } from "./__generated__/common";
import { BigNumber } from 'ethers'
import { JsonRpcProvider } from '@ethersproject/providers'

function useContractCallBoolean(method: string, contract: Contract): boolean | undefined {
  const { value, error } = useCall({
    contract: contract,
    method: method,
    args: []
  }) ?? {}
  if (error) {
    console.error(error.message)
    return undefined
  }
  return value?.[0]
}

function useContractCallNumber(method: string, contract: Contract): BigNumber | undefined {
  const { value, error } = useCall({
    contract: contract,
    method: method,
    args: []
  }) ?? {}
  if (error) {
    console.error(error.message)
    return undefined
  }
  return value?.[0]
}

function useSaleActive(contract: Contract): boolean | undefined {
  return useContractCallBoolean("saleActive", contract)
}

function usePresaleActive(contract: Contract): boolean | undefined {
  return useContractCallBoolean("presaleActive", contract)
}

function useNFTPriceInPurchaseToken(contract: Contract): string | undefined {
  const nftPriceInPurchaseToken = useContractCallNumber("NFTPriceInPurchaseToken", contract);
  if(nftPriceInPurchaseToken) {
    const smallerValue = utils.formatEther(nftPriceInPurchaseToken);
    if(smallerValue.indexOf(".") > -1)
      return smallerValue.split(".")[0];
    else
      return smallerValue;
  }
  return nftPriceInPurchaseToken;
}

function useCurrentCollectionSize(contract: Contract): BigNumber | undefined {
  return useContractCallNumber("currentCollectionSize", contract);
}

function useTotalSupply(contract: Contract): BigNumber | undefined {
  return useContractCallNumber("totalSupply", contract);
}

export const useApprove = (tokenAddress: string) => {
  const erc20 = useERC20(tokenAddress);
  const {
    state: approveState,
    send: approve,
    resetState: resetApprove,
  } = useContractFunction(erc20, "approve");
  return { approveState, approve, resetApprove };
};

export const useFlameCultistSaleActive = () => {
  const flameCultist = useFlameCultist();
  return useSaleActive(flameCultist) || false;
};

export const useFlameCultistPresaleActive = () => {
  const flameCultist = useFlameCultist();
  return usePresaleActive(flameCultist) || false;
};

export const useFlameCultistNFTPriceInPurchaseToken = () => {
  const flameCultist = useFlameCultist();
  return useNFTPriceInPurchaseToken(flameCultist);
};

export const useFlameCultistMinted = () => {
  const flameCultist = useFlameCultist();
  return useTotalSupply(flameCultist);
};

export const useFlameCultistCurrentCollectionSize = () => {
  const flameCultist = useFlameCultist();
  return useCurrentCollectionSize(flameCultist);
};

export const useFlameCultistMint = () => {
  const flameCultist = useFlameCultist();
  const {
    state: flameCultistMintState,
    send: flameCultistMint,
    resetState: resetFlameCultistMint,
  } = useContractFunction(flameCultist, "mint");
  return { flameCultistMintState, flameCultistMint, resetFlameCultistMint };
};

export const usePlagueDoctorSaleActive = () => {
  const plagueDoctor = usePlagueDoctor();
  return useSaleActive(plagueDoctor) || false;
};

export const usePlagueDoctorPresaleActive = () => {
  const plagueDoctor = usePlagueDoctor();
  return usePresaleActive(plagueDoctor) || false;
};

export const usePlagueDoctorNFTPriceInPurchaseToken = () => {
  const plagueDoctor = usePlagueDoctor();
  return useNFTPriceInPurchaseToken(plagueDoctor);
};

export const usePlagueDoctorMinted = () => {
  const plagueDoctor = usePlagueDoctor();
  return useTotalSupply(plagueDoctor);
};

export const usePlagueDoctorCurrentCollectionSize = () => {
  const plagueDoctor = usePlagueDoctor();
  return useCurrentCollectionSize(plagueDoctor);
};

export const usePlagueDoctorMint = () => {
  const plagueDoctor = usePlagueDoctor();
  const {
    state: plagueDoctorMintState,
    send: plagueDoctorMint,
    resetState: resetPlagueDoctorMint,
  } = useContractFunction(plagueDoctor, "mint");
  return { plagueDoctorMintState, plagueDoctorMint, resetPlagueDoctorMint };
};

export const useFireLordSaleActive = () => {
  const fireLord = useFireLord();
  return useSaleActive(fireLord) || false;
};

export const useFireLordPresaleActive = () => {
  const fireLord = useFireLord();
  return usePresaleActive(fireLord) || false;
};

export const useFireLordNFTPriceInPurchaseToken = () => {
  const fireLord = useFireLord();
  return useNFTPriceInPurchaseToken(fireLord);
};

export const useFireLordMinted = () => {
  const fireLord = useFireLord();
  return useTotalSupply(fireLord);
};

export const useFireLordCurrentCollectionSize = () => {
  const fireLord = useFireLord();
  return useCurrentCollectionSize(fireLord);
};

export const useFireLordMint = () => {
  const fireLord = useFireLord();
  const {
    state: fireLordMintState,
    send: fireLordMint,
    resetState: resetFireLordMint,
  } = useContractFunction(fireLord, "mint");
  return { fireLordMintState, fireLordMint, resetFireLordMint };
};

export async function getBalance(tokenAddress: string, account: string, library: any): Promise<BigNumber> {
  const erc20 = getERC20(
    tokenAddress,
    (library as JsonRpcProvider)?.getSigner()
  );

  return await erc20.balanceOf(account as PromiseOrValue<string>);
}
