import React, { useState, useEffect } from 'react'
import { useEthers } from '@usedapp/core'
import { Button, Typography, Stack } from '@mui/material'
import styled from '@emotion/styled'
import Web3Modal, { ThemeColors } from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { SnackBarContext } from '../../types'

const ConnectButton = styled(Button)({
  '&.MuiButton-root': {
    height: '30',
    color: 'white',
    display: 'flex',
    backgroundColor: 'red',
    padding: '8 24 24 24',
    borderRadius: '4',
    margin: '8',
    float: 'right',
    fontsize: '18',
    alignItems: 'stretch',
    cursor: 'pointer',
  },
})

const DisconnectButton = styled(Button)({
  '&.MuiButton-root': {
    height: '30',
    color: 'white',
    display: 'flex',
    backgroundColor: 'red',
    padding: '8 24 24 24',
    borderRadius: '4',
    margin: '8',
    float: 'right',
    fontsize: '18',
    alignItems: 'stretch',
    cursor: 'pointer',
  },
})

const changeBackground = e => {
  e.target.style.background = 'chocolate';
}
const resetBackground = e => {
  e.target.style.background = 'red';
}

const ConnectWalletButton: React.FC<React.PropsWithChildren<SnackBarContext>> = ({ setShowSubmissionFailure, setSubmissionMessage }: SnackBarContext) => {
  const [activateError, setActivateError] = useState('');

  const { account, activate, deactivate, error } = useEthers();

  useEffect(() => {
    if (error && account) {
      setSubmissionMessage(error.message);
      setShowSubmissionFailure(true);
    }
  }, [error, account])

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: 'Metamask',
          description: 'Connect with the provider in your Browser',
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: 'https://bridge.walletconnect.org',
          infuraId: 'd8df2cb7844e4a54ab0a782f608749dd',
        },
      },
    }
    //https://data-seed-prebsc-1-s1.binance.org:8545
    //https://data-seed-prebsc-2-s2.binance.org:8545
    const web3Modal = new Web3Modal({
      providerOptions,
      theme: {
        background: "#282323",
        main: "#FFFFFF",
        secondary: "#FFFFFF"
      } as ThemeColors
    })

    try {
      const provider = await web3Modal.connect()
      await activate(provider)
    } catch (error: any) {
      setSubmissionMessage(error.message);
      setShowSubmissionFailure(true);
    }
  }

  const onConnect = () => {
    activateProvider();
  };

  const onDisconnect = () => {
    deactivate();
  };

  return (
    <div>
      {
        account
          ?
          <Stack
            direction="row"
            justifyContent={{xs: "center", sm: "end"}}
            alignItems="center"
          >
            <Typography
              pr={2}
              sx={{ fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {`${account.slice(0, 6)}...${account.slice(-7)}`}
            </Typography>

            <DisconnectButton
              onClick={onDisconnect}
              className="btn"
              onMouseOver={changeBackground}
              onMouseOut={resetBackground}
            >
              Disconnect
            </DisconnectButton>
          </Stack>
          :
          <Stack
            direction="row"
            justifyContent={{xs: "center", sm: "end"}}
          >
            <ConnectButton
              onClick={onConnect}
              className="btn"
              onMouseOver={changeBackground}
              onMouseOut={resetBackground}
            >
              Connect Wallet

            </ConnectButton>
          </Stack>

      }
    </div>
  )
}

export default ConnectWalletButton
