import React from 'react'
import {
  Box,
  Grid,
  Card,
  CardContent,
  Container,
  Snackbar,
  Alert,
  Link
} from '@mui/material'
import {
  useFlameCultistSaleActive,
  useFlameCultistPresaleActive,
  useFlameCultistNFTPriceInPurchaseToken,
  useFlameCultistMinted,
  useFlameCultistCurrentCollectionSize,
  usePlagueDoctorSaleActive,
  usePlagueDoctorPresaleActive,
  usePlagueDoctorNFTPriceInPurchaseToken,
  usePlagueDoctorMinted,
  usePlagueDoctorCurrentCollectionSize,
  useFireLordSaleActive,
  useFireLordPresaleActive,
  useFireLordNFTPriceInPurchaseToken,
  useFireLordMinted,
  useFireLordCurrentCollectionSize
} from '../../contracts/functions'
import PromoBoard from '../PromoBoard'
import { useState } from 'react'
import logo from '../../images/Signature_400x100.png'
import MintBoard from '../MintBoard'

const MainBoard = () => {
  const [submissionMessage, setSubmissionMessage] = useState('')
  const [transactionHash, setTransactionHash] = useState('')
  const [showSubmissionPending, setShowSubmissionPending] = useState(false)
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false)
  const [showSubmissionFailure, setShowSubmissionFailure] = useState(false)

  const flameCultistSaleActive = useFlameCultistSaleActive();
  const flameCultistPresaleActive = useFlameCultistPresaleActive();
  const flameCultistNFTPriceInPurchaseToken = useFlameCultistNFTPriceInPurchaseToken()?.toString() || '';
  const flameCultistMinted = useFlameCultistMinted()?.toString() || '';
  const flameCultistAvailable = useFlameCultistCurrentCollectionSize()?.toString() || '';
  const plagueDoctorSaleActive = usePlagueDoctorSaleActive();
  const plagueDoctorPresaleActive = usePlagueDoctorPresaleActive();
  const plagueDoctorNFTPriceInPurchaseToken = usePlagueDoctorNFTPriceInPurchaseToken()?.toString() || '';
  const plagueDoctorMinted = usePlagueDoctorMinted()?.toString() || '';
  const plagueDoctorAvailable = usePlagueDoctorCurrentCollectionSize()?.toString() || '';
  const fireLordSaleActive = useFireLordSaleActive();
  const fireLordPresaleActive = useFireLordPresaleActive();
  const fireLordNFTPriceInPurchaseToken = useFireLordNFTPriceInPurchaseToken()?.toString() || '';
  const fireLordMinted = useFireLordMinted()?.toString() || '';
  const fireLordAvailable = useFireLordCurrentCollectionSize()?.toString() || '';

  const saleContext = {
    flameCultistSaleActive: flameCultistSaleActive,
    flameCultistPresaleActive: flameCultistPresaleActive,
    flameCultistNFTPriceInPurchaseToken: flameCultistNFTPriceInPurchaseToken,
    flameCultistMinted: flameCultistMinted,
    flameCultistAvailable: flameCultistAvailable,
    plagueDoctorSaleActive: plagueDoctorSaleActive,
    plagueDoctorPresaleActive: plagueDoctorPresaleActive,
    plagueDoctorNFTPriceInPurchaseToken: plagueDoctorNFTPriceInPurchaseToken,
    plagueDoctorMinted: plagueDoctorMinted,
    plagueDoctorAvailable: plagueDoctorAvailable,
    fireLordSaleActive: fireLordSaleActive,
    fireLordPresaleActive: fireLordPresaleActive,
    fireLordNFTPriceInPurchaseToken: fireLordNFTPriceInPurchaseToken,
    fireLordMinted: fireLordMinted,
    fireLordAvailable: fireLordAvailable
  };

  const snackBarContext = {
    setShowSubmissionSuccess: setShowSubmissionSuccess,
    setShowSubmissionPending: setShowSubmissionPending,
    setShowSubmissionFailure: setShowSubmissionFailure,
    setSubmissionMessage: setSubmissionMessage,
    setTransactionHash: setTransactionHash
  }

  return (
    <Box>
      <Snackbar
        open={showSubmissionSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSubmissionSuccess(false)
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setShowSubmissionSuccess(false)
          }}
          severity="success"
          sx={{ width: '100%' }}
          className="mobileSnackbarTextSize"
        >
          {submissionMessage} Transaction hash:{' '}
          <a
            style={{ color: 'white' }}
            href={`https://bscscan.com/tx/${transactionHash}`}
            target="_blank"
            rel="noreferrer"
          >
            {transactionHash}
          </a>
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSubmissionFailure}
        autoHideDuration={6000}
        onClose={() => {
          setShowSubmissionFailure(false)
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setShowSubmissionFailure(false)
          }}
          severity="error"
          sx={{ width: '100%' }}
          className="mobileSnackbarTextSize"
        >
          {submissionMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSubmissionPending}
        autoHideDuration={20000}
        onClose={() => {
          setShowSubmissionPending(false)
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setShowSubmissionPending(false)
          }}
          severity="info"
          sx={{ width: '100%' }}
          className="mobileSnackbarTextSize"
        >
          {submissionMessage}{' '}
          {transactionHash && (
            <b>
              Transaction hash:{' '}
              <a
                style={{ color: 'white' }}
                href={`https://bscscan.com/tx/${transactionHash}`}
                target="_blank"
                rel="noreferrer"
              >
                {transactionHash}
              </a>
            </b>
          )}
        </Alert>
      </Snackbar>

      <Container maxWidth={false}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
        >
          <Link href="https://onlyburns.com/" target="_blank" rel="noreferrer">
            <img style={{ maxWidth: '100%' }} src={logo} alt="OnlyBurns.com"></img>
          </Link>
        </Grid>

        <Box sx={{
          paddingTop: "3%",
          justifyContent: "stretch",
          width: "100%"
        }}>
          <Grid
            container
            spacing={''}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              width: '100%',
            }}
          >
            {/* Mint Board */}
            <Grid id="mint-board-container" item xs={12} md={6}>
              <Box sx={{ p: '2%' }}>
                <Card
                  sx={{
                    border: '2px solid grey',
                    p: '2%',
                    height: '100%',
                    background: 'rgba(50,50,50,0.8)',
                    borderRadius: '1.5em',
                    width: '100%',
                  }}
                >
                  <CardContent>
                    <MintBoard saleContext={saleContext} snackBarContext={snackBarContext}></MintBoard>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            {/* Promo Board */}
            <Grid id="promo-board-container" item xs={12} md={6}>
              <Box sx={{ p: '2%' }}>
                <Card
                  sx={{
                    border: '2px solid grey',
                    p: '2%',
                    height: '100%',
                    background: 'rgba(40, 35, 35, 1)',
                    borderRadius: '1.5em',
                    width: '100%',
                  }}
                >
                  <CardContent>
                    <PromoBoard saleContext={saleContext}></PromoBoard>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container >
    </Box >

  )
}

export default MainBoard
