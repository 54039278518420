import React, { useEffect, useState } from 'react'
import {
  Typography,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import ADDRESSES from '../../contracts/addresses'
import {
  useTokenAllowance,
  useEthers,
  TransactionStatus,
} from '@usedapp/core'
import { ethers } from 'ethers'
import { SaleContext, SnackBarContext, TransactionStateChangeMessages } from '../../types'
import {
  useApprove,
  useFlameCultistMint,
  usePlagueDoctorMint,
  useFireLordMint
} from '../../contracts/functions'
import CurrentlyMinted from '../CurrentlyMinted'
import ConnectWalletButton from '../ConnectWalletButton'
import MintBoardSubmitButton from '../MintBoardSubmitButton'

interface MintBoardProps {
  saleContext: SaleContext,
  snackBarContext: SnackBarContext
}

const MintBoard = ({ saleContext, snackBarContext }: MintBoardProps) => {
  const [tier, setTier] = useState(ADDRESSES.FLAME_CULTIST)
  const [tierPresaleActive, setTierPresaleActive] = useState(saleContext.flameCultistPresaleActive)
  const [numberToMint, setNumberToMint] = useState('1')
  const [totalPrice, setTotalPrice] = useState('100')
  const [isApproved, setIsApproved] = useState(false)
  const { approve: approveBUSD, approveState: approveBUSDState } = useApprove(ADDRESSES.BUSD_ERC20)
  const { flameCultistMint, flameCultistMintState } = useFlameCultistMint()
  const { plagueDoctorMint, plagueDoctorMintState } = usePlagueDoctorMint()
  const { fireLordMint, fireLordMintState } = useFireLordMint()
  const { account, switchNetwork, chainId } = useEthers()

  const checkChain = async () => {
    // const Mainnet = { chainId: 97 };
    const Mainnet = { chainId: 56 };
    if (chainId !== Mainnet.chainId) {
      await switchNetwork(Mainnet.chainId)
    }
  }

  const mintFlameCultist = () => {
    flameCultistMint(numberToMint);
  }

  const mintPlagueDoctor = () => {
    plagueDoctorMint(numberToMint);
  }

  const mintFireLord = () => {
    fireLordMint(numberToMint);
  }

  const useAllowance = (address: string, spender: string, owner?: string) => {
    checkChain();
    return useTokenAllowance(
      address,
      owner,
      spender);
  }

  const allowance = useAllowance(ADDRESSES.BUSD_ERC20, tier, account);

  useEffect(() => {
    if (allowance && allowance.gt(0)) {
      setIsApproved(true);
    }
    else {
      setIsApproved(false);
    }
  }, [allowance]);

  const processTransactionStateChange = (state: TransactionStatus, submissionMessage: TransactionStateChangeMessages) => {
    if (state.status === 'Success') {
      snackBarContext.setShowSubmissionSuccess(true)
      snackBarContext.setShowSubmissionPending(false)
      snackBarContext.setShowSubmissionFailure(false)

      snackBarContext.setSubmissionMessage(submissionMessage.success);

      snackBarContext.setTransactionHash(
        state.transaction ? state.transaction.hash : '',
      )

    } else if (state.status === 'Exception') {
      snackBarContext.setShowSubmissionSuccess(false)
      snackBarContext.setShowSubmissionPending(false)
      snackBarContext.setShowSubmissionFailure(true)
      snackBarContext.setSubmissionMessage(`${submissionMessage.exception}: ${state.errorMessage}`)
    } else if (state.status === 'Mining') {
      snackBarContext.setShowSubmissionSuccess(false)
      snackBarContext.setShowSubmissionPending(true)
      snackBarContext.setShowSubmissionFailure(false)
      snackBarContext.setSubmissionMessage(submissionMessage.mining)
      snackBarContext.setTransactionHash(
        state.transaction ? state.transaction.hash : '',
      )
    } else if (state.status === 'PendingSignature') {
      snackBarContext.setShowSubmissionSuccess(false)
      snackBarContext.setShowSubmissionPending(true)
      snackBarContext.setShowSubmissionFailure(false)
      snackBarContext.setSubmissionMessage(submissionMessage.pendingSignature)
      snackBarContext.setTransactionHash('')
    }
  }

  const processApprovalStateChange = (approveState: TransactionStatus) => {
    processTransactionStateChange(approveState, {
      success: "BUSD spend approved successfully! Click Mint to Complete Transaction! ",
      exception: "Failed to approve BUSD spend",
      mining: "Approving BUSD spend... ",
      pendingSignature: "Waiting for User to Sign Transaction..."
    });
  }

  useEffect(() => {
    processApprovalStateChange(approveBUSDState)
  }, [approveBUSDState])

  const processMintStateChange = (name: string, mintState: TransactionStatus) => {
    processTransactionStateChange(mintState, {
      success: `${name} minted successfully! `,
      exception: `Failed to mint ${name}`,
      mining: `Minting ${name}... `,
      pendingSignature: "Waiting for User to Sign Transaction..."
    });
  }

  const processFlameCultistMintStateChange = (mintState: TransactionStatus) => {
    processMintStateChange("Flame Cultist", mintState);
  }

  const processPlagueDoctorMintStateChange = (mintState: TransactionStatus) => {
    processMintStateChange("Plague Doctor", mintState);
  }

  const processFireLordMintStateChange = (mintState: TransactionStatus) => {
    processMintStateChange("Fire Lord", mintState);
  }

  useEffect(() => {
    processFlameCultistMintStateChange(flameCultistMintState)
  }, [flameCultistMintState])

  useEffect(() => {
    processPlagueDoctorMintStateChange(plagueDoctorMintState)
  }, [plagueDoctorMintState])

  useEffect(() => {
    processFireLordMintStateChange(fireLordMintState)
  }, [fireLordMintState])

  const approveBUSDSpend = (address: string) => {
    approveBUSD(
      address,
      ethers.constants.MaxInt256,
    )
  }
  const approveFlameCultistBUSDSpend = () => {
    approveBUSDSpend(ADDRESSES.FLAME_CULTIST);
  };

  const approvePlagueDoctorBUSDSpend = () => {
    approveBUSDSpend(ADDRESSES.PLAGUE_DOCTOR);
  };

  const approveFireLordBUSDSpend = () => {
    approveBUSDSpend(ADDRESSES.FIRE_LORD);
  };

  const tierChanged = (newTier: string) => {
    setTier(newTier || ADDRESSES.FLAME_CULTIST);
    numberToMintChanged("1");
  }

  const numberToMintChanged = (newNumberToMint: string) => {
    setNumberToMint(newNumberToMint || "1");
  }

  useEffect(() => {
    calculateTotalPrice();
  }, [tier, numberToMint, saleContext.flameCultistNFTPriceInPurchaseToken, saleContext.plagueDoctorNFTPriceInPurchaseToken, saleContext.fireLordNFTPriceInPurchaseToken]);

  useEffect(() => {
    setTierPresaleActive(ADDRESSES.FLAME_CULTIST && saleContext.flameCultistPresaleActive
      || tier == ADDRESSES.PLAGUE_DOCTOR && saleContext.plagueDoctorPresaleActive
      || tier == ADDRESSES.FIRE_LORD && saleContext.fireLordPresaleActive);
  }, [tier, numberToMint]);

  const calculateTotalPrice = () => {
    const tier1PricePerNFT = parseInt(saleContext.flameCultistNFTPriceInPurchaseToken);
    const tier2PricePerNFT = parseInt(saleContext.plagueDoctorNFTPriceInPurchaseToken);
    const tier3PricePerNFT = parseInt(saleContext.fireLordNFTPriceInPurchaseToken);

    let pricePerNFT = tier1PricePerNFT;
    if (tier === ADDRESSES.PLAGUE_DOCTOR) {
      pricePerNFT = tier2PricePerNFT;
    }
    else if (tier === ADDRESSES.FIRE_LORD) {
      pricePerNFT = tier3PricePerNFT;
    }

    const newTotalPrice = pricePerNFT * parseInt(numberToMint);
    setTotalPrice(isNaN(newTotalPrice) ? "" : newTotalPrice.toString());
  }

  return (
    <Grid id='mint-board' container>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          align="center"
          pb={2}
          sx={{ fontWeight: 'bold' }}
        >
          Mint your OnlyBurns NFT!{' '}
        </Typography>
      </Grid>

      <Grid item xs={12} pb={2}>
        <ConnectWalletButton
          setShowSubmissionSuccess={snackBarContext.setShowSubmissionSuccess}
          setShowSubmissionPending={snackBarContext.setShowSubmissionPending}
          setShowSubmissionFailure={snackBarContext.setShowSubmissionFailure}
          setSubmissionMessage={snackBarContext.setSubmissionMessage}
          setTransactionHash={snackBarContext.setTransactionHash}>
        </ConnectWalletButton>
      </Grid>

      <Grid item xs={12} pb={2}>
        <Typography
          noWrap
          color="white"
          variant="h5"
          sx={{ fontWeight: 'bold', textAlign: { xs: "center", sm: "start" } }}
        >
          Currently Minted:
        </Typography>
      </Grid>

      <Grid item xs={12} xl={6} pb={{ xs: 0, sm: 6 }}>
        <Grid container
          id="currently-minted-container"
          justifyContent={{ xs: "center", sm: "start" }}
          pr={{ xs: 0, sm: 6 }}
        >
          <Grid item xs={6} sm={4}>
            <CurrentlyMinted tier={1} current={saleContext.flameCultistMinted} max={saleContext.flameCultistAvailable}></CurrentlyMinted>
          </Grid>
          <Grid item xs={6} sm={4}>
            <CurrentlyMinted tier={2} current={saleContext.plagueDoctorMinted} max={saleContext.plagueDoctorAvailable}></CurrentlyMinted>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CurrentlyMinted tier={3} current={saleContext.fireLordMinted} max={saleContext.fireLordAvailable}></CurrentlyMinted>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid 
          container
          direction={{xs: "column", sm: "row"}}
          flexWrap="nowrap"
          justifyContent={{xs: "space-between", lg: "space-evenly"}}
        >
          <Grid item pb={{ xs: 2, sm: 0 }}>
            <FormControl sx={{ width: { xs: "100%", sm: "auto" } }}>
              <FormHelperText
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingBottom: "1em"
                }}>
                Select desired Tier
              </FormHelperText>
              <Select
                sx={{
                  borderRadius: ".5em",
                  bgcolor: 'black',
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '3px solid',
                    borderColor: 'rgba(250, 250, 250, 1)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 1)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 1)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important',
                  },
                  '& #my-simple-select': {
                    fontSize: '1rem',
                  }

                }}
                onChange={(e) => tierChanged(e.target.value)}
                value={tier}
                id="tier"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: 'grey',
                    },
                  },
                }}
              >
                <MenuItem value={ADDRESSES.FLAME_CULTIST}>Tier 1</MenuItem>
                <MenuItem value={ADDRESSES.PLAGUE_DOCTOR}>Tier 2</MenuItem>
                <MenuItem value={ADDRESSES.FIRE_LORD}>Tier 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item pb={{ xs: 2, sm: 0 }} pl={{sm: 1, xl: 0}} pr={{sm: 1, xl: 0}}>
            <FormControl sx={{ width: { xs: "100%", sm: "auto" } }}>
              <FormHelperText
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingBottom: "1em"
                }}>
                Select desired amount
              </FormHelperText>
              <Select
                sx={{
                  borderRadius: ".5em",
                  bgcolor: 'black',
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '3px solid',
                    borderColor: 'rgba(250, 250, 250, 1)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 1)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 1)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important',
                  },
                  '& #my-simple-select': {
                    fontSize: '1rem',
                  }

                }}
                placeholder="Choose Amount"
                onChange={(e) => numberToMintChanged(e.target.value)}
                value={numberToMint}
                id="amount"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: 'grey',
                    },
                  },
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2} sx={{ "display": (!tierPresaleActive && (tier === ADDRESSES.FLAME_CULTIST || tier === ADDRESSES.PLAGUE_DOCTOR) ? "" : "none") }}>2</MenuItem>
                <MenuItem value={3} sx={{ "display": (!tierPresaleActive && (tier === ADDRESSES.FLAME_CULTIST || tier === ADDRESSES.PLAGUE_DOCTOR) ? "" : "none") }}>3</MenuItem>
                <MenuItem value={4} sx={{ "display": (!tierPresaleActive && (tier === ADDRESSES.FLAME_CULTIST) ? "" : "none") }}>4</MenuItem>
                <MenuItem value={5} sx={{ "display": (!tierPresaleActive && (tier === ADDRESSES.FLAME_CULTIST) ? "" : "none") }}>5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item display="flex" justifyContent="center" alignItems="end">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  noWrap={true}
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}>
                  Total Price:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  noWrap={true}
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}>
                  {totalPrice} BUSD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          padding={4}
          direction="column"
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              pb={1}
              sx={{ fontWeight: 'bold' }}
            >
              Approve and Mint!
            </Typography>

            {tier === ADDRESSES.FLAME_CULTIST &&
              <MintBoardSubmitButton
                connected={account}
                approved={isApproved}
                approve={approveFlameCultistBUSDSpend}
                mint={mintFlameCultist}
              />
            }

            {tier === ADDRESSES.PLAGUE_DOCTOR &&
              <MintBoardSubmitButton
                connected={account}
                approved={isApproved}
                approve={approvePlagueDoctorBUSDSpend}
                mint={mintPlagueDoctor}
              />
            }

            {tier === ADDRESSES.FIRE_LORD &&
              <MintBoardSubmitButton
                connected={account}
                approved={isApproved}
                approve={approveFireLordBUSDSpend}
                mint={mintFireLord}
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MintBoard
