import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Config, DAppProvider, BSC, BSCTestnet } from "@usedapp/core";
import { store } from "./store";
import { Provider } from "react-redux";

const testnetConfig: Config = {
  readOnlyChainId: BSCTestnet.chainId,
  readOnlyUrls: {
    [BSCTestnet.chainId]: 'https://data-seed-prebsc-2-s3.binance.org:8545'
  },
  networks: [BSCTestnet],
  bufferGasLimitPercentage: 15,
};

const config = {
  networks: [BSC],
  readOnlyChainId: BSC.chainId,
  readOnlyUrls: {
    [BSC.chainId]: 'https://bsc.getblock.io/900d2868-5a6d-4355-a8a2-a5ae7cbcd0a9/mainnet/',
  },
  gasLimitBufferPercentage: 15,
  notifications: {
    expirationPeriod: 1000, //milliseconds
    checkInterval: 1000, //milliseconds for timeout
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <DAppProvider config={config}>
    <Provider store={store}>
      <App />
    </Provider>
  </DAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
