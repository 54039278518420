import { useState } from 'react';
import { Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import CarouselCard from '../CarouselCard';
import Typography from '@mui/material/Typography';
import { SaleContext } from '../../types';
import tier1a from '../../assets/samples/tier1a.png'
import tier1b from '../../assets/samples/tier1b.png'
import tier2a from '../../assets/samples/tier2a.png'
import tier2b from '../../assets/samples/tier2b.png'
import tier3a from '../../assets/samples/tier3a.png'
import tier3b from '../../assets/samples/tier3b.png'

interface PromoBoardProps {
  saleContext: SaleContext
}

export default function PromoBoard({ saleContext }: PromoBoardProps) {
  const [tier, setTier] = useState("1");
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);

  const tiers = {
    "1": {
      totalSupply: "1000",
      price: saleContext.flameCultistNFTPriceInPurchaseToken,
      stageMaxSupply: saleContext.flameCultistAvailable
    },
    "2": {
      totalSupply: "500",
      price: saleContext.plagueDoctorNFTPriceInPurchaseToken,
      stageMaxSupply: saleContext.plagueDoctorAvailable
    },
    "3": {
      totalSupply: "250",
      price: saleContext.fireLordNFTPriceInPurchaseToken,
      stageMaxSupply: saleContext.fireLordAvailable
    },
  }

  return (
    <Grid id='promo-board' container p={2} paddingTop={0}>
      <Grid item xs={12}>
          <Typography
              variant="h4"
              align="center"
              pb={2}
              sx={{ fontWeight: 'bold' }}
          >
              Tier {tier}
          </Typography>
      </Grid>
      <Grid item xs={12}>
          <Grid container display={{xs: "none", sm: "flex"}}>
              <Grid item xs={6}>
                  <Typography
                      variant="h5"
                      pb={2}
                      sx={{ fontSize: {xs: '1rem', md: '1.5rem'}, fontWeight: 'bold' }}
                  >
                      Total Supply: {tiers[tier].totalSupply}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography
                      align="right"
                      variant="h5"
                      pb={2}
                      sx={{ fontSize: {xs: '1rem', md: '1.5rem'}, fontWeight: 'bold' }}
                  >
                      Available during
                  </Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography
                      variant="h5"
                      pb={2}
                      sx={{ fontSize: {xs: '1rem', md: '1.5rem'}, fontWeight: 'bold' }}
                  >
                      Price during Stage 1: ${tiers[tier].price}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography
                      align="right"
                      variant="h5"
                      pb={2}
                      sx={{ fontSize: {xs: '1rem', md: '1.5rem'}, fontWeight: 'bold' }}
                  >
                      Stage 1: {tiers[tier].stageMaxSupply}
                  </Typography>
              </Grid>
          </Grid>
          <Grid container display={{xs: "flex", sm: "none"}}>
              <Grid item xs={12}>
                  <Typography
                      variant="h5"
                      pb={2}
                      align="center"
                      sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}
                  >
                      Total Supply: {tiers[tier].totalSupply}
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Typography
                      variant="h5"
                      pb={2}
                      align="center"
                      sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}
                  >
                      Available during Stage 1: {tiers[tier].stageMaxSupply}
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Typography
                      variant="h5"
                      pb={2}
                      align="center"
                      sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}
                  >
                      Price during Stage 1: ${tiers[tier].price}
                  </Typography>
              </Grid>
          </Grid>          
      </Grid>      
      <Grid item xs={12}>
        <img
          src={tier1a}
          onLoad={() => setFirstImgLoaded(true)}
          style={{ display: "none" }}
        />
        {firstImgLoaded && (
          <Carousel
            activeIndicatorIconButtonProps={{ style: { color: "#E91C24" } }}
            indicatorIconButtonProps={{ style: { color: "#3A3232" } }}
            interval={5000}
            navButtonsAlwaysVisible={true}
            onChange={(now) => {
              const index = typeof(now) === "undefined" ? 1 : now + 1;
              setTier(index.toString())
            }}
          >
            <CarouselCard
              imageLeft={tier1a}
              imageRight={tier1b}
            ></CarouselCard>

            <CarouselCard
              imageLeft={tier2a}
              imageRight={tier2b}
            ></CarouselCard>

            <CarouselCard
              imageLeft={tier3a}
              imageRight={tier3b}
            ></CarouselCard>
          </Carousel>
        )}
      </Grid>
    </Grid>
  );
}
